
import { defineComponent } from "vue";
import {loadPaginatedECWalletHistory}  from "@/api/onlineshop";
import ECWalletReload from "@/components/ecwallet/ECWalletReload.vue";
import BalanceDetails from "@/components/cards/BalanceDetails.vue";

export default defineComponent({
  name: "ecwallethistory", 
  methods: {
    async loadPaginatedECWalletHistory(){ 
      this.loading = true;
      const response = await loadPaginatedECWalletHistory(1);
      console.log(response);
      this.list = response.data.pageList;
      this.hasPendingECWalletOTP = response.data.hasPendingECWalletOTP;
      this.ccode = response.data.ccode;
      this.currency = response.data.currency;
      this.isAllowedECWallet = response.data.isShowECWalletReloadDiv;
      this.loading = false;
      
    },
    async refreshECWalletProcessModal(){ 
      this.loadPaginatedECWalletHistory();
    },
  },
  mounted(){
      this.loadPaginatedECWalletHistory();
      if(localStorage.getItem("override_cifid") != null){
        this.isOverriden = true;
      }
  },
  data(){
      return { 
        list : [],
        hasPendingECWalletOTP : "",
        loading: true,
        isOverriden : false,
        ccode : -1,
        currency : '',
        isAllowedECWallet : false
      }
  },
  components: {
    'ecwallet-process' : ECWalletReload,
    BalanceDetails
  },
  props: {
    widgetClasses: String
  }
});

