
import { defineComponent } from "vue";
import {process_ecwalletReload, loadPaginatedECWalletHistory, process_ecwalletOTP}  from "@/api/onlineshop";
import Swal from "sweetalert2/dist/sweetalert2.min.js";


export default defineComponent({
  name: "ecwalletprocess", 
  data(){
      return { 
        hasPendingECWalletOTP : false,
        optionsRadios : 1,
        totalAmount : "0",
        totalAmount2 : 0,
        model_ecwallet_otp : "0",
        isProcessingOTP : false,
        isProcessingReload : false,
        loading: true,
      }
  },
  methods: {
    async loadPaginatedECWalletHistory(){ 
      this.loading = true;
        const response = await loadPaginatedECWalletHistory();
        this.hasPendingECWalletOTP = response.data.hasPendingECWalletOTP;
      this.loading = false;
    },
    async cancel_ecwalletReload(){
        this.totalAmount = "0";
        this.totalAmount2 = 0;
    },
    async computeTotalAmount(amountClicked:number){
      var totalAmount = this.totalAmount2;

      if(this.optionsRadios == 1){
        totalAmount = Number(totalAmount) + Number(amountClicked);
      } else {
        totalAmount = Number(totalAmount) - Number(amountClicked);
      }

      if(totalAmount < 0){
        totalAmount = 0;
      }

      this.totalAmount2 = totalAmount;
      this.totalAmount = String(totalAmount);
      return totalAmount;
    },
    async process_ecwalletReload(){
      this.loading = true;
      if(isNaN(this.totalAmount2) || this.totalAmount2 == 0){
        this.isProcessingReload = true;
        Swal.fire({
              title: "",
              text: "Total must be greater than 0.",
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            }); 
            this.isProcessingReload = false;
            return;
      } else {
        this.isProcessingReload = true;
        var param = {"amount" : this.totalAmount2};
        const response = await process_ecwalletReload(param);

        if(response != null){
          this.isProcessingReload = false;
          
          if (response.data.result == "SUCCESS") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            }); 
            window.location.reload();
            return; 
          } else if(response.data.result == "FAILED"){
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            }); 
            return; 
          }
        }
      }
      this.loading = false;
    },
    async process_ecwalletOTP(){
      this.loading = true;
      this.isProcessingOTP = true;
      var param = {"otp" : this.model_ecwallet_otp};
        const response = await process_ecwalletOTP(param);
        if(response != null){
          this.isProcessingOTP = false;
          
          if (response.data.result == "SUCCESS") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            }); 
            window.location.reload();
            return; 
          } else if(response.data.result == "FAILED"){
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            }); 
            return; 
          }
        }
        this.loading = false;
    },
    async reset_ecwallet(){
      this.totalAmount = "0";
      this.totalAmount2 = 0;
    },
  },
  mounted(){
      this.loadPaginatedECWalletHistory();
  },
  components: {},
  props: {
    widgetClasses: String,
    ccode : String,
    currency : String
  }
});
