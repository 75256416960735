
import { defineComponent, onMounted, ref } from "vue";
import { config } from "@/core/helpers/config";
import { themeName } from "@/core/helpers/documentation";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ECWalletHistory from "@/components/ecwallet/ECWalletHistory.vue";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from "@/views/ECShopAdvisory.vue";


export default defineComponent({
  name: "ecwallethistory",
  components: {
    ECWalletHistory,
    // CompanyPolicy,
    // ECShopAdvisory
  },
  data(){
    return {
      pt : "",
    }
  },
  setup() {
    const tabIndex = ref(0);
    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("onlineShopTab") || "0");

      setCurrentPageTitle("Online Shop");

      
    });
  },
});
